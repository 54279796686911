.filter-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 14px;
    margin-bottom: 14px;
    color: #8997a5;
    color: #091440;
    width: 100%;

    @include media-breakpoint-down(md) {
        flex-direction: row-reverse;
    }

    .message {
        display: none;
        color: rgba($white, 0.45);
        font-size: 15px;
        margin: 0 30px 0 0;
        text-align: right;

        @include media-breakpoint-down(md) {
            font-size: 13px;
            line-height: 15px;
            margin: 0 0 0 10px;
        }
    }
    
    .filter-button {
        padding: 12px;
        margin: 0 15px 0 0;

        cursor: pointer;
        background-color: transparent;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        opacity: 0.45;
        
        filter: grayscale(100%) brightness(10);
    
        @include media-breakpoint-down(md) {
            margin: 0 10px 0 0;
        }

        &:hover,
        &:focus {
            opacity: 0.60;
        }

        &.instant_exchange {
            background-image: url('../images/exchange-icons/instant-exchange.svg');
        }
    
        &.high_liquidity {
            background-image: url('../images/exchange-icons/high-liquidity.svg');
        }
    
        &.fiat {
            background-image: url('../images/exchange-icons/fiat.svg');
        }
    
        &.decentralized {
            background-image: url('../images/exchange-icons/decentralized.svg');
        }
    
        &.atomic_swaps {
            background-image: url('../images/exchange-icons/atomicswaps.svg');
        }

        &.hardware_wallets {
            background-image: url('../images/wallet-icons/hardware.svg');
        }

        &.core_software {
            background-image: url('../images/wallet-icons/core.svg');
        }
        
        &.third_party_wallets {
            background-image: url('../images/wallet-icons/thirdparty.svg');
        }
    }
}


#high_liquidity:checked   ~ div label[for='high_liquidity'],
#instant_exchange:checked ~ div label[for='instant_exchange'],
#atomic_swaps:checked     ~ div label[for='atomic_swaps'],
#fiat:checked             ~ div label[for='fiat'],
#decentralized:checked    ~ div label[for='decentralized'] {
    filter: none;
    opacity: 1.0;
}

#hardware_wallets:checked    ~ div label[for='hardware_wallets'],
#core_software:checked       ~ div label[for='core_software'],
#third_party_wallets:checked ~ div label[for='third_party_wallets'] {
    filter: none;
    opacity: 1.0;
}

#high_liquidity:checked   ~ .subpage-header .filter-buttons .message.high_liquidity,
#instant_exchange:checked ~ .subpage-header .filter-buttons .message.instant_exchange,
#atomic_swaps:checked     ~ .subpage-header .filter-buttons .message.atomic_swaps,
#fiat:checked             ~ .subpage-header .filter-buttons .message.fiat,
#decentralized:checked    ~ .subpage-header .filter-buttons .message.decentralized {
    display: inline-block;
}

#hardware_wallets:checked    ~ .subpage-header .filter-buttons .message.hardware_wallets,
#core_software:checked       ~ .subpage-header .filter-buttons .message.core_software,
#third_party_wallets:checked ~ .subpage-header .filter-buttons .message.third_party_wallets {
    display: inline-block;
}

#high_liquidity:checked   ~ .pills .high_liquidity   .pill,
#instant_exchange:checked ~ .pills .instant_exchange .pill,
#atomic_swaps:checked     ~ .pills .atomic_swaps     .pill,
#fiat:checked             ~ .pills .fiat             .pill,
#decentralized:checked    ~ .pills .decentralized    .pill {
    border: 2px solid rgba($turquoise, 1.0);
}

#hardware_wallets:checked    ~ .wallet-cards .hardware_wallets,
#core_software:checked       ~ .wallet-cards .core_software,
#third_party_wallets:checked ~ .wallet-cards .third_party_wallets {
    border: 1px solid rgba($turquoise, 1.0);
}