footer {
    background-color: $darker-blue;
    color: $white;
    padding-top: 55px;
    padding-bottom: 76px;
    
    @include media-breakpoint-up(xl) {
        padding-bottom: 114px;
    }

    .footer-link,
    .footer-link:hover {
        font-size: 15px;
        margin-right: 16px;
        margin-bottom: 3px;
        @include media-breakpoint-up(xl) {
            margin-bottom: 12px;
        }
    
    }

    a,
    a:hover {
        margin-bottom: 14px;
        color: $white;
        text-decoration: none;
        opacity: 0.55;
    }

    .social-icon,
    .social-icon:hover {
        width: 34px;
        height: 34px;
        
        margin-bottom: 21px;
        background-position: center center;
        background-size: 34px 34px;
        background-repeat: no-repeat;

        @include media-breakpoint-up(xl) {
            margin-right: 34px;
            margin-bottom: 13px;
        }
        
        &.br {
            background-image: url('../images/social-icons/br.svg');
        }

        &.youtube {
            background-image: url('../images/social-icons/youtube.svg');
        }

        &.discord {
            background-image: url('../images/social-icons/discord.svg');
        }

        &.telegram {
            background-image: url('../images/social-icons/telegram.svg');
        }

        &.matrix {
            background-image: url('../images/social-icons/matrix.svg');
        }

        &.github {
            background-image: url('../images/social-icons/github.svg');
        }

        &.x {
            background-image: url('../images/social-icons/x.svg');
        }

        &.reddit {
            background-image: url('../images/social-icons/reddit.svg');
        }

        &.medium {
            background-image: url('../images/social-icons/medium.svg');
        }
    }

}
