* {
    z-index: 0;
}

body {
    background: $dark-blue;
    min-height: 100vh;
    display: flex;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.navbar-margin {
    margin-top: $navbar-height;
    display: flex;
    flex: 1;
    flex-direction: column;
}

a {
    outline: 0;
}

.light-hyphen {
    font-weight: 200;
    &.translucent {
        color: rgba($white, 0.34);
    }
}

.body-gradient {
    background-image: url("../images/bg-gradient.svg");
    background-position: 0% 0%;
    background-size: cover;
    background-repeat: no-repeat;
}

$subpage-header-height: 352px;

.subpage-fronta {
    position: relative;
    height: $subpage-header-height;
    width: 100%;

    * {
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
    }

    .gradient1 {
        background: linear-gradient(200deg, #0f2666 0%, #091440 62%, #091440 100%);
    }
    
    .gradient2 {
        margin-top: -$subpage-header-height;
        background: linear-gradient(225deg, #122E7600 0%, #112B7000 4%, #0D215B27 21%, #0B194CB2 39%, #091542F1 57%, #091440 79%, #091440 100%);
    }

    .exchange-header {
        margin-top: -$subpage-header-height;

        background-image: url('../images/subpage-backgrounds/exchanges-extrawide.svg');
        background-position: calc(50% + 300px) $navbar-height;
        background-attachment: fixed;

        @include media-breakpoint-down(xl) {
            background-position: calc(50% + 230px) $navbar-height;
        }
        @include media-breakpoint-down(lg) {
            background-position: calc(50% + 120px) $navbar-height;
        }
        @include media-breakpoint-down(md) {
            background-position: calc(50% + 25px) $navbar-height;
        }
    }

    .wallet-header {
        margin-top: -$subpage-header-height;

        background-image: url('../images/subpage-backgrounds/xl/wallets.svg');
        background-position: right $navbar-height;
        background-attachment: fixed;
        
        @include media-breakpoint-down(xl) {
            background-image: url('../images/subpage-backgrounds/lg/wallets.svg');
        }
        @include media-breakpoint-down(lg) {
            background-image: url('../images/subpage-backgrounds/md/wallets.svg');
        }
        @include media-breakpoint-down(md) {
            background-image: url('../images/subpage-backgrounds/sm/wallets.svg');
        }
    }

    .community-header {
        margin-top: -$subpage-header-height;

        background-image: url('../images/subpage-backgrounds/xl/community.svg');
        background-position: right $navbar-height;
        background-attachment: fixed;
        
        @include media-breakpoint-down(xl) {
            background-image: url('../images/subpage-backgrounds/lg/community.svg');
        }
        @include media-breakpoint-down(lg) {
            background-image: url('../images/subpage-backgrounds/md/community.svg');
        }
        @include media-breakpoint-down(md) {
            background-image: url('../images/subpage-backgrounds/sm/community.svg');
        }
    }

}

.subpage-padding {
    padding-bottom: 123px;
    flex: 1;
}

.subpage-header {
    padding-top: 89px;
    padding-bottom: 42px;
    display: flex;
    flex-direction: row;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    @include media-breakpoint-down(md) {
        padding-top: 55px;
    }

    .subpage-title {
        width: 100%;
        color: $white;
        font-family: "dcrweb-poppins", "Verdana", sans-serif;
        font-size: 34px;
    }
}

.subpage-paragraph {
    padding-bottom: 36px;
    color: $white;
    opacity: 0.75;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    @include media-breakpoint-down(md) {
        font-size: 15px;
        line-height: 18px;
    }
}

.orange {
    color: $orange;
}

.warning {
    color: $white;
    margin-top: 41px;
    border: 1px solid $orange;
    border-radius: 8px;
    font-size: 13px;
    line-height: 18px;
    padding: 21px;
    
    @include media-breakpoint-down(md) {
        padding: 13px;
    }

    a {
        color: $white;
    }

    p {
        margin: 0;
    }
}

.pills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    a {
        text-decoration: none;
        color: white;
    }

    .pill {
        padding: 15px 24px 14px;
        margin-right: 13px;
        margin-bottom: 13px;
        border-radius: 10000px;
        white-space: nowrap;
        transition: border 0.21s ease-in-out;
        
        border: 2px solid rgba($white, 0.21);
        font-size:18px;
        
        @include media-breakpoint-down(md) {
            font-size: 15px;
            padding: 11px 19px;
            margin-right: 8px;
            margin-bottom: 8px;
        }
        
        &:hover,
        &:focus {
            border: 2px solid rgba($white, 0.55);
        }

        &.highlight {
            border: 2px solid rgba($light-blue, 0.7);

            &:hover,
            &:focus {
                border: 2px solid rgba($light-blue, 1.0);
            }
        }

        &.super-highlight {
            border: 2px solid rgba($turquoise, 0.7);

            &:hover,
            &:focus {
                border: 2px solid rgba($turquoise, 1.0);
            }
        }

    }
}