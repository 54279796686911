// 
// Category filters.
// 

#all:target               ~ .news-toggle #all_filter           .pill,
#coverage:target          ~ .news-toggle #coverage_filter      .pill,
#software_releases:target ~ .news-toggle #software_filter      .pill,
#press_releases:target    ~ .news-toggle #press_release_filter .pill,
#decred_journals:target   ~ .news-toggle #journal_filter       .pill {
  border: 2px solid rgba($turquoise, 1.0);
}

// Default when no target is selected.
#all_filter .pill {
  border: 2px solid rgba($turquoise, 1.0);
}
:target ~ .news-toggle #all_filter .pill {
  border: 2px solid rgba($white, 0.21);
}

#software_releases:target ~ .news-list .journal,
#software_releases:target ~ .news-list .coverage,
#software_releases:target ~ .news-list .press_release {
  display: none;
}

#coverage:target ~ .news-list .journal,
#coverage:target ~ .news-list .software_release,
#coverage:target ~ .news-list .press_release {
  display: none;
}

#press_releases:target ~ .news-list .journal,
#press_releases:target ~ .news-list .software_release,
#press_releases:target ~ .news-list .coverage {
  display: none;
}

#decred_journals:target ~ .news-list .coverage,
#decred_journals:target ~ .news-list .software_release,
#decred_journals:target ~ .news-list .press_release {
  display: none;
}

.news-list {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 20px;

  .news-item {
    width: 100%;
    padding: 22px 23px 18px;
    position: relative;
    border-bottom: 1px solid rgba($white, 0.5);
  
    &:hover {
      .news-item-wrapper {
        .news-item-title {  
          color: rgba($white, 0.95);
        }
      }
  
      img.news-external-link {
        opacity: 1;
      }
    }
  
    a {
      text-decoration: none;
      display: flex;
    }
    
    p {
      margin-bottom: 5px;
    }
  
    img.news-icon {
      float: left;
      margin-right: 34px;
      vertical-align: top;
      margin-bottom: 0;
      height: 30px;
      width: 30px;
    }
    
    img.news-external-link {
      position: absolute;
      top: 30px;
      right: 15px;
      margin-right: 0;
      opacity: 0;
      transition: 0.08s ease-in-out;
    }
  
    .news-item-wrapper {
      float: left;
      max-width: calc(100% - 80px);
    
      .news-item-title {
        color: rgba($white, 0.75);
        font-size: 19px;
        line-height: 24px;
        transition: 0.08s ease-in-out;
        margin-right: 5px;
      }
    
      .news-author {
        color: rgba($white, 0.5);
        font-size: 14px;
        line-height: 24px;
        transition: 0.08s ease-in-out;
        margin-right: 5px;
      }
    
      .news-source,
      .news-date {
        color: #8997A5;
        font-size: 11px;
        line-height: 15px;
      }
    }
  }
}



// 
// Single press release article.
// 

.press-release-article {
  line-height: 24px;
  max-width: 680px;
  margin: auto;
  padding-top: 30px;
  
  
  .article-date {
    color: rgba($white, 0.55);
  }

  h1, h2, h3, h4, h5, h6 {
    color: rgba($white, 0.8);
  }

  h1 {
    font-size     : 27px;
    padding-top   : 10px;
    padding-bottom: 10px;
  }

  h2 {
    font-size     : 25px;
    font-weight   : bold;
    padding-top   : 10px;
    padding-bottom: 8px;
}

  h3 {
    color: rgba($white, 0.8);
    font-size     : 22px;
    padding-top   : 8px;
    padding-bottom: 4px;
  }

  h4 {
    font-size     : 19px;
    font-weight   : bold;
}

  h5 {
    color: rgba($white, 0.7);
    font-size     : 17px;
  }

  h6 {
    font-size     : 16px;
    font-weight   : bold;
}

  p, li {
    color: rgba($white, 0.7);
  }

  strong {
    color: rgba($white, 0.8);
  }

  ul,
  ol {
    margin-left: 8px;
    margin-bottom: 14px;
  }
  
  blockquote {
    border-left: .2rem solid rgba($white, 0.5);
    padding: 0;
    margin-bottom: 30px;
    p {
      color: rgba($white, 0.75);
      padding-right: 0;
      padding-left: 1.0rem;
      padding-top: .2rem;
      padding-bottom: .2rem;
      line-height: 28px;
      font-style: italic;
    }
  }
  
  a {
    color: rgba($white, 0.895);
    text-decoration: underline;
  }
}
