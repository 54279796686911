@font-face {
    font-family: "dcrweb-code";
    src:
        url("../fonts/SourceCodePro-Regular/SourceCodePro-Regular.ttf.woff2") format("woff2"),
        url("../fonts/SourceCodePro-Regular/SourceCodePro-Regular.ttf.woff")  format("woff"),
        url("../fonts/SourceCodePro-Regular/SourceCodePro-Regular.ttf")       format("truetype"),
        url("../fonts/SourceCodePro-Regular/SourceCodePro-Regular.eot")       format("embedded-opentype");
    font-display: swap;
}

@font-face {
    font-family: "dcrweb-inter";
    src: url("../fonts/Inter/Inter-Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "dcrweb-inter";
    src: url("../fonts/Inter/Inter-Medium.ttf") format("truetype");
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: "dcrweb-inter";
    src: url("../fonts/Inter/Inter-Light.ttf") format("truetype");
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: "dcrweb-inter";
    src: url("../fonts/Inter/Inter-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: "dcrweb-inter";
    src: url("../fonts/Inter/Inter-Thin.ttf") format("truetype");
    font-weight: 100;
    font-display: swap;
}

@font-face {
    font-family: "dcrweb-poppins";
    src: url("../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "dcrweb-poppins";
    src: url("../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "dcrweb-poppins";
    src: url("../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: "dcrweb-poppins";
    src: url("../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "dcrweb-poppins";
    src: url("../fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: "dcrweb-poppins";
    src: url("../fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

html, body {
    font-family: "dcrweb-inter", "Verdana", sans-serif;
}

pre, code {
    font-family: "dcrweb-code", "Courier New", monospace;
}

.dcrwebcode {
    font-family: "dcrweb-code", "Courier New", monospace;
}
